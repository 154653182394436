import React from 'react'
import moment from 'moment'
import { graphql } from 'gatsby'

import SEO from '../components/SEO/SEO'
import DynamicZone from '../components/DynamicZone/DynamicZone'

const articleTemplate = ( { data: { strapi: { event } }, location } ) => {

	return (
		<>
			<SEO title={ `${ event.eventTitle } | Events` } description={ event.seoDescription } pathname={ location.pathname } />

			<div className="article wrapper">
				<div className="article__wrapper">

					<h1 className="article__header">{ event.eventTitle }</h1>

					<h3 className="article__sub-header">
						{ event.eventLocation && <span className="article__sub-header-date">{ event.eventLocation } - </span> }
						<span className="article__sub-header-date">{ moment( event.eventDateStart ).format( "DD.MM.YY" ) }</span>
						{ event.eventDateEnd !== event.eventDateStart && <span className="article__sub-header-date">- { moment( event.eventDateEnd ).format( "DD.MM.YY" ) }</span> }
						{ event.additionalEndDateInfo && <span className="article__sub-header-date">{ event.additionalEndDateInfo }</span> }
					</h3>

					<div className="article__body-content">
						{ event && event.content &&
							<DynamicZone components={ event.content } />
						}
					</div>

				</div>
			</div>
		</>
	)
}

export default articleTemplate

export const query = graphql`
	query eventQuery($id: ID!) {
		strapi {
			event(id: $id) {
				id
				seoDescription
				eventTitle
				eventLocation
				eventDateStart
				eventDateEnd
				additionalEndDateInfo
				content {
					... on StrapiQuery_ComponentArticleTextBlock {
						id
						text
					}
					... on StrapiQuery_ComponentArticleImage {
						id
						title
						wideImage
						image {
							url
							imageFile {
								childImageSharp {
									fluid(maxWidth: 1000) {
										...GatsbyImageSharpFluid_noBase64
									}
								}
							}
						}
					}
					... on StrapiQuery_ComponentArticleQuote {
						id
						author
						quote
					}
					... on StrapiQuery_ComponentCommonSectionLink {
						id
						linkTitle
						linkUrl
					}
					... on StrapiQuery_ComponentArticleVideo {
						id
						videoTitle
						videoNumber
					}
				}
			}
		}
	}
`